<template>
	<v-col class="mx-auto" cols="12" lg="11" xl="10" :class="$vuetify.breakpoint.width < 600 ? 'px-2':'px-5'" ref="container">
		<v-row
				v-if="mode !== 'creation' && mode !== 'multiple_creation' && ready"
				align="center"
				no-gutters
				:class="[$vuetify.breakpoint.width >= 600 ? '':'px-1', mode !== 'modification' ? 'mb-2':'']"
		>
			<v-col :cols="tag_field !== null ? 'auto':''">
				<div class="sub-title-1 font-weight-bold" :class="methods.includes('update') ? '':'warning--text'">
					<v-tooltip top>
						<template v-slot:activator="{ on }">
							<v-row class="mx-0" v-on="!methods.includes('update') ? on : null">
								<v-icon v-if="!methods.includes('update')" size="20" class="mb-1 mr-1" color="warning">mdi-lock</v-icon>
								<div>{{ item.display_name | firstCapital }}</div>
							</v-row>
						</template>
						<span>{{ $t('single_trad.Item.locked') }}</span>
					</v-tooltip>
				</div>
			</v-col>
			<v-col v-if="tag_field !== null" class="px-3">			
				<TagField
						v-model="item"
						:field="tag_field"
						:pevents="events"
						:path="path"
						:pathId="$route.params[$route.name]"
						:oldVal="old_val"
						:editable="methods.includes('update')"
						@newItemCreated="e => tag_field.choices.push(e.item)"
						@saved="old_val = JSON.parse(JSON.stringify(item))"
				/>
			</v-col>
			<v-col cols="auto" v-if="routeChildren.filter(e => e.position === 'item_list').length > 0 && mode !== 'modification'" class="pr-2" v-show="!drawer && !edit_items">
				<v-menu offset-y left rounded="lg">
					<template v-slot:activator="{ on, attrs }">
						<v-btn
								color="celldark"
								dark
								v-on="on"
								class="rounded-lg"
								small
								height="30"
								:loading="loading"
						>
							<v-row align="center" dense>
								<v-col style="max-width: 24px">
								</v-col>
								<v-col>
									<div class="caption-1 font-weight-bold">{{ $t('single_trad.Table.config') }}</div>
								</v-col>
								<v-col class="pr-0" cols="auto">
									<v-icon>mdi-menu-down</v-icon>
								</v-col>
							</v-row>
						</v-btn>
					</template>
					<v-list class="rounded-lg">
						<v-list-item
								v-for="(btn, i) in routeChildren.filter(e => e.position === 'item_list')"
								:key="i"
								@click="() => { $router.push(computeLink(btn.endpoint)) }"
						>
							<v-list-item-icon class="mr-2">
								<v-icon small>{{ btn.icon }}</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<div class="caption-1 pr-2">{{ btn.label }}</div>
							</v-list-item-content>
						</v-list-item>
					</v-list>
				</v-menu>
			</v-col>
			<v-col cols="auto">
				<Actions
						:show="!drawer && !edit_items"
						v-if="mode !== 'creation' && mode !== 'multiple_creation' && mode !== 'modification'"
						:actions="actions"
						:methods="methods"
						:path="path + '/' + $route.params[$route.name]"
						:inItem="true"
						:noExtras="!routeComponents.includes('extra') || !methods.includes('update')"
						:pevents="events"
						@modify="mode = 'modification'"
						@extras="events.notify('open-dialog')"
						@fieldgroups="drawer = true; drawer_dashboard = !drawer"
						@delete="dialog = true"
						@items="edit_items = true"
				/>
				<Button v-if="mode === 'modification' || drawer || edit_items" :label=" $t('global.action.save')" color="primary" :click="drawer ? () => { saveFieldGroups(); initLayout(); } : edit_items ? () => { saveItemsLayout() } : () => {mode = 'edition'}" :loading="loading" />
				<Button v-if="mode === 'modification' || drawer || edit_items" classes="ml-2" dark :label=" $t('global.action.cancel')" color="celldark" :click=" drawer ?() => { drawer = false; field_groups = field_groups_copy; drawer_dashboard = true} : () => {edit_items = false; mode= 'edition'}" :loading="loading" />
			</v-col>
		</v-row>

		<v-row v-if="(mode === 'creation' || mode === 'modification') && ready">
			<v-col :class="mode === 'modification' ? '':'pt-0'">
				<div :class="[$store.getters['style/classes'].container, $vuetify.breakpoint.width >= 600 ? '':'px-1 rounded-0']">
					<FieldGroup
							v-model="item"
							:path="path"
							:pathId="$route.params[$route.name]"
							:fields="$tools.sortObject($tools.filterObject(fields, e => !e.read_only), 'label')"
							:mode="mode"
							:title="mode === 'modification' ? '': $route.meta.route_label"
							:pevents="events"
							@created="(e) => $router.push('/dashboard/' + path + '/' + e.id)"
							@newItemCreated="(e) => fields[e.field].choices.unshift(e.item)"
							@close="mode = 'edition'"
					/>
				</div>
			</v-col>
		</v-row>

		<v-row v-else-if="mode === 'multiple_creation' && ready">
			<v-col class="pt-0" v-for="(form,i) in forms" :key="'item_'+i" cols="12" :id="'form_' + i">
				<div :class="$store.getters['style/classes'].container">
					<v-row no-gutters align="center">
						<v-col>
							<div class="sub-title-2">{{ $tc('global.name.item', 1) + ' : ' + (i + 1) }}</div>
						</v-col>
						<v-col cols="auto">
							<Button iconVal="mdi-chevron-up" :classes="form.open ? '':'transition-rotate'" icon small :click="() => form.open = !form.open" />
						</v-col>
						<v-col cols="auto">
							<Button iconVal="mdi-close" icon iconSmall small :click="() => forms.splice(i, 1)" />
						</v-col>
					</v-row>
					<v-expand-transition>
						<FieldGroup
								v-show="form.open"
								v-model="forms[i].item"
								:path="path"
								:pathId="$route.params[$route.name]"
								:fields="fields"
								:mode="mode"
								:pevents="events"
								@newItemCreated="(e) => fields[e.field].choices.unshift(e.item)"
								@formOk="(e) => forms[i].ok = e"
								@ready="i > 1 ? scrollForm() : ''"
								@fileField="(e) => forms[i].fileField = e"
						/>
					</v-expand-transition>
				</div>
			</v-col>
			<v-col cols="12">
				<v-row>
					<v-col>
						<Button
								large
								:block="$vuetify.breakpoint.width < 600"
								color="secondary"
								:label="$tc('single_trad.Item.add_form', 1)"
								:click="() => addForm()"
								iconVal="mdi-plus"
								iconPosition="left"
						/>
					</v-col>
					<v-col>
						<Button
								large
								block
								color="primary"
								:label="$tc('ItemActions.create_multiple', 1)"
								:click="() => forms[0].fileField ? createMultipleWithFile() : createMultiple()"
								:disabled="!formsOk"
								:loading="loading"
						/>
					</v-col>
				</v-row>
			</v-col>
		</v-row>

		<div v-else-if="(mode === 'edition' || mode === 'readonly') && ready">
			<v-row class="fill-height">
				<v-col class="pt-0 pb-1" cols="12" sm="auto" v-if="Object.keys(logo_field).length > 0" style="max-width: 212px">
					<FieldGroup
							v-model="item"
							:path="path"
							:pathId="$route.params[$route.name]"
							:fields="logo_field"
							:mode="mode"
							:pevents="events"
					/>
				</v-col>
				<v-col class="pt-0 pb-2">
					<div :class="$store.getters['style/classes'].container">
						<FieldGroup
								v-model="item"
								:path="path"
								:pathId="$route.params[$route.name]"
								:fields="$tools.sortObject(default_fields, 'label')"
								:mode="mode"
								:pevents="events"
								:split="split"
						/>
					</div>
				</v-col>
			</v-row>

			<draggable
				v-show="!drawer"
					v-model="items_layout"
					handle=".handle2"
					ghost-class="ghost"
			>
				<div
						v-for="(row, y) in items_layout"
						:key="y"
						v-if="row.items.length > 0 || edit_items"
				>
					<v-row align="center" class="mx-0" v-if="row.title.toLowerCase() !== 'default' || edit_items">
						<v-col cols="auto" class="pl-0 pr-1 pt-0 pb-2" :class="items_layout.length > 1 ? 'handle2':''">
							<v-icon v-if="edit_items" size="20" class="pb-1" :color="items_layout.length > 1 ? '':'cell3'">mdi-drag-horizontal-variant</v-icon>
							<Button v-else :click="() => items_layout[y].open = !items_layout[y].open" icon iconSize="20" carre iconVal="mdi-chevron-up" :classes="items_layout[y].open ? '':'transition-rotate'"></Button>
						</v-col>
						<v-col class="pl-0 pt-0 pb-2">
							<div v-if="!edit_items" class="paragraph">{{ row.title.toLowerCase() === 'default' ? '' : row.title | firstCapital }}</div>
							<TextField v-else v-model="items_layout[y].title" />
						</v-col>

						<v-col cols="auto" v-if="edit_items" class="pl-0 pt-0 pb-1">
							<Button :click="() => deleteRow(y)" carre iconVal="mdi-delete" color="error" :disabled="items_layout.length === 1"></Button>
						</v-col>
						<v-col cols="auto" v-if="edit_items" class="px-0 pt-0 pb-1">
							<Button :click="() => items_layout[y].open = !items_layout[y].open" icon iconSize="22" carre iconVal="mdi-chevron-up" :classes="items_layout[y].open ? '':'transition-rotate'"></Button>
						</v-col>
					</v-row>

					<v-expand-transition >
						<div :class="edit_items && row.open ? 'pa-2 p mb-2 rounded-lg':''" :style="edit_items ? {backgroundColor: $tools.transparentize('primary', 0.4, $vuetify)} : {}">
							<draggable
									v-show="row.open"
									v-model="row.items"
									class="gridcontainer transition-cubic"
									:class="edit_items ? '' : 'mb-2'"
									ghost-class="ghost"
									handle=".handle"
									:group="{ name: 'widgets' }"
									:style="{
										gridTemplateColumns: 'repeat('+ cols + ', '+ unit +'px)',
										gridTemplateRows: unit + 'px'
									}"
							>
								<ItemWidget
										v-show="it.type === 'extra' && extra_items.length === 0 ? false : drawer ? it.type === 'fieldgroup' && it.data.name !== 'default' : true"
										v-for="it in row.items"
										:key="it.key"
										v-model="item"
										:unit="unit"
										:data="it.data"
										:type="it.type"
										:editItems="edit_items"
										:cols="cols"
										:pevents="events"
										:path="path"
										:mode="mode"
										:editFields="drawer"
										:extraItems="extra_items"
										:methods="methods"
										:full="field_groups.length === 2 && field_groups[1].fields.length === 0"
										@deleteFieldGroup="(e) => deleteFieldGroup(e)"
										@newItemCreated="(e) => fields[e.field].choices.unshift(e.item)"
										@newItemCreatedStatus="(e) => fieldStatus.field.choices.unshift(e.item)"
										@bottomSheet="e => bottom_sheet = e"
										@update="e => events.notify('parent-saved', e)"
								/>
							</draggable>

						</div>


					</v-expand-transition>
				</div>
			</draggable>

			<v-divider class="my-3 mx-6" v-show="edit_items && items_layout.length > 0" />

			<v-row class="mx-0" no-gutters justify="center" v-show="edit_items">
				<v-col class="text-center">
					<Button color="secondary" :label="$t('single_trad.Home.add_row')" iconVal="mdi-plus" iconPosition="right" :click="() => items_layout.push({
							title: '',
							open: true,
							items: []
						})"
					/>
					<div class="sub-title-2 mt-3">{{ $t('single_trad.Item.layout') }}</div>
				</v-col>
			</v-row>
		</div>

		<v-row v-if="drawer" dense>
			<v-col v-for="(group,i) in field_groups" v-if="group.name !== 'default'" :key="i" cols="12" md="6" >
				<div :class="$store.getters['style/classes'].container">
					<v-row no-gutters class="pb-2">
						<v-col>
							<TextField v-model="group.name" />
						</v-col>
						<v-col cols="auto">
							<Button iconVal="mdi-delete" small icon carre iconSize="16" :click="() => deleteFieldGroup(i)" />
						</v-col>
					</v-row>

					<draggable v-model="field_groups[i].fields" style="min-height: 40px; margin: 0 -8px" :group="{ name: 'field_groups', put: true, pull: true }" class="row">
						<v-col cols="6" class="pa-2" v-for="(field, j) in group.fields" :key="j">
							<v-row align="center" style="cursor: grab" class="cell2 elevation-2 rounded-lg paragraph text-truncate pa-2" no-gutters>
								<v-col>{{ field.label }}</v-col>
								<v-spacer />
								<v-col cols="auto">
									<Button iconVal="mdi-close" icon :click="() => { field_groups.find(g => g.name === 'default').fields.push(field); field_groups[i].fields.splice(j, 1) }" />
								</v-col>
							</v-row>
						</v-col>
					</draggable>
				</div>
            </v-col>
			<v-divider class="mx-4 mt-3 col-12" v-if="drawer" style="grid-column: span 2" />
			<v-col align="center">
				<Button :label="$t('single_trad.Item.add_group')" color="secondary" :click="() => { field_groups.push({name: '', fields: []})}" />
			</v-col>
			<v-col cols="12" align="center" class="paragraph">{{ $t('single_trad.Item.drag') }}</v-col>
		</v-row>

		<ComponentLoader v-else-if="!ready" :type="mode === 'edition' || mode === 'readonly' ? 'item':'item-creation'" />

		<v-expand-transition>
			<v-row
					v-show="bottom_sheet"
					class="cell3 mx-0 elevation-2 mt-4 rounded-lg"
					justify="center"
					align="center"
					style="position: sticky; bottom: 12px; z-index: 1;"
			>
				<v-col cols="12" class=" sub-title-2">{{ $tc('single_trad.FieldGroup.save_changes', 1) }}</v-col>
				<v-spacer></v-spacer>
				<v-col>
					<Button
							block
							color="grey darken-1"
							dark
							:label="$tc('global.action.cancel', 1)"
							:click="() => closeBottomSheet()"
					/>
				</v-col>
				<v-col>
					<Button
							:loading="loading"
							block
							color="primary"
							:label="$tc('global.action.save', 1)"
							:click="() => saveChanges()"
					/>
				</v-col>
			</v-row>
		</v-expand-transition>

		<Dialog
				v-model="dialog"
				type="confirm"
				:message="$tc('single_trad.Table.dialog_v_delete', 1, {item: item.display_name})"
				@confirm="deleteItem()"
				@cancel="dialog = false"
				:loading="loading"
		/>
		<Dialog
				v-model="dialog_save"
				type="confirm"
				:message="$t('single_trad.Item.page_change')"
				@confirm="() => {drawer = false; drawer_dashboard = true; this.$router.push(to)}"
				@cancel="dialog_save = false"
				:loading="loading"
		/>
	</v-col>
</template>

<script>
	export default {
		name: "Item",
		props: ['routeComponents', 'routeParent', 'routeChildren', 'pevents'],
		components: {
			ComponentLoader: () => import('@/components/ui/ComponentLoader.vue'),
			FieldGroup: () => import('@/components/FieldGroup.vue'),
			Button: () => import('@/components/ui/Button.vue'),
			Dialog: () => import('@/components/ui/Dialog.vue'),
			Actions: () => import('@/components/Actions.vue'),
			TextField: () => import('@/components/fields/TextField.vue'),
			ImageField: () => import('@/components/fields/ImageField.vue'),
			ItemWidget: () => import('@/components/ItemWidget.vue'),
			TagField: () => import('@/components/fields/TagField.vue'),
			draggable: () => import('vuedraggable')
		},
		data(){
			return {
				dialog: false,
				dialog_save:false,
				to: '',
				path: '',
				fields: {},
				mode: '',
				item: {},
				actions: [],
				methods: [],
				ready: false,
				forms: [
					{
						index: 0,
						ok: false,
						open: true,
						item: {}
					},
					{
						index: 1,
						ok: false,
						open: true,
						item: {}
					}
				],
				loading: false,
				extra_items: [],
				fieldStatus: {},
				fieldStatusProgression: {},
				events: null,
				field_groups: [],
				field_groups_copy: [],
				widgets: [],
				template_layout: [
					{
						title: 'Widgets',
						open: true,
						items: []
					},
					{
						title: 'Default',
						open: true,
						items: []
					}
				],
				items_layout: [],
				edit_items: false,
				cols: 8,
				unit: 0,
				ready_layout: 3,
				bottom_sheet: false,
				tag_field: null,
				fields_changed: [],
				old_val: {},
				default_fields: {},
				logo_field: {},
				split: 1
			}
		},
		computed: {
			formsOk(){
				let res = this.forms.length

				this.forms.forEach(e => {
					if(e.ok){
						res--
					}
				})

				return res === 0 && this.forms.length !== 0
			},
			drawer: {
				get(){
					return this.$store.getters['dashboard/drawer_item']
				},
				set(val){
					this.$store.commit('dashboard/CHANGE_DRAWER_ITEM_VAL', val)
				}
			},
			drawer_dashboard: {
				get(){
					return this.$store.getters['dashboard/drawer']
				},
				set(val){
					this.$store.commit('dashboard/CHANGE_DRAWER_VAL', val)
				}
			}
		},
		watch: {
			"$route"(){
				this.computePath()
			},
			ready_layout(val){
				if(val === 0){
					this.initLayout()
				}
			}
		},
		methods: {
			addForm(){
				this.forms.push({index: this.forms.length, ok: false, open: true, item: {}})
			},
			computeLink(endpoint) {
				let res = []

				if (endpoint) {
					for (let i of endpoint.split('/')) {
						if (i.match(':')) {
							let vat = i.replace(':', '')
							res.push(this.$route.params[vat])
						} else {
							res.push(i)
						}
					}
				}

				return '/dashboard/' + res.join('/')
			},
			computePath() {
				this.computeSplit()

				if (this.$route.params[this.$route.name] === 'create') {
					this.path = this.$route.path.replace('/dashboard/', '').replace('/create', '')
					this.mode = 'creation'
				} else if (this.$route.params[this.$route.name] === 'create_multiple') {
					this.path = this.$route.path.replace('/dashboard/', '').replace('/create_multiple', '')
					this.mode = 'multiple_creation'

					if(typeof this.$store.getters['dashboard/multiple_items'] !== 'undefined') {
						if (this.$store.getters['dashboard/multiple_items'].length > 0) {
							this.forms = []
							this.$store.getters['dashboard/multiple_items'].forEach((e, i) => {
								this.forms.push(
									{
										index: i,
										ok: false,
										open: true,
										item: e
									}
								)
							})

							this.$store.commit('dashboard/CHANGE_MULTIPLE_ITEMS_VAL', [])
						}
					}
				} else if (this.$route.params[this.$route.name]) {
					this.path = this.$route.path.replace('/dashboard/', '')
					let array = this.path.split('/')
					let id = this.path.split('/')[array.length - 1]
					this.path = this.path.substr(0, this.path.length - (id.length + 1))
					this.mode = 'edition'
				} else {
					this.path = this.$route.path.replace('/dashboard/', '')
					this.mode = 'edition'
				}

				this.initItemPage()
			},
			computeSplit(){
				if(this.$vuetify.breakpoint.width > 1904){
					this.split = 3
				}
				else if(this.$vuetify.breakpoint.width > 1264 && this.$vuetify.breakpoint.width <= 1904){
					this.split = 2 + (Object.keys(this.logo_field).length > 0 ? 0 : 1)
				}
				else if(this.$vuetify.breakpoint.width > 960 && this.$vuetify.breakpoint.width <= 1264){
					this.split = 1
				}
				else if(this.$vuetify.breakpoint.width > 600 && this.$vuetify.breakpoint.width <= 960){
					this.split = 1
				}
				else {
					this.split = 1
				}
			},
			createMultipleWithFile(){
				this.loading = true
				let ready = this.forms.length
				let errors = 0

				this.forms.forEach(f => {
					let data = f.item

					this.$wsc.toFormData(data, (succ, header) => {
						this.$wsc.createItem(this.path, succ, success => {
							ready--
							this.$store.dispatch('snackbar/success', this.$tc('snackbar.Item.createAllSingle.success', 1, {index: f.index}))

							if(ready === 0){
								this.$router.push('/dashboard/' + this.path)
								this.loading = false
								this.$store.dispatch('snackbar/success', this.$t('snackbar.Item.createAll.success'))
							}
						}, fail => {
							errors++

							if(errors === this.forms.length){
								this.loading = false
							}
						}, header)
					})
				})
			},
			createMultiple(){
				this.loading = true

				let data = this.forms.map(e => e.item)

				this.$wsc.createBatch(this.path, data, success => {
				
					this.$router.push('/dashboard/' + this.path)
					this.loading = false
					this.$store.dispatch('snackbar/success', this.$t('snackbar.Item.createAll.success'))
				}, fail => {
					this.loading = false
				})
			},
			deleteFieldGroup(index){
				let defIndex = this.field_groups.findIndex(g => g.name === 'default')

				this.field_groups[index].fields.forEach((f, i)=> {
					this.field_groups[defIndex].fields.push(f)
				})

				this.field_groups.splice(index, 1)
				if ( this.field_groups.length === 1)
					this.field_groups.push({name:'', fields:[]})
				this.pevents.notify('fieldGroups', this.field_groups)

				this.initLayout()
			},
			deleteItem(){
				this.loading = true

				this.$wsc.deleteItem(this.path, this.$route.params[this.$route.name], success => {
					this.$router.push('/dashboard/' + this.path)
					this.dialog = false
					this.$store.dispatch('snackbar/success', this.$t('snackbar.Set.deleteItem.success'))
				}, fail => {
					this.loading = false
				})
			},
			deleteRow(index){
				let items =  JSON.parse(JSON.stringify(this.items_layout[index].items))

				this.items_layout.splice(index, 1)

				if (this.items_layout.length === 0){
					this.items_layout.push({title: 'Default', open: true, items: items})
				}
				else {
					items.forEach(i => {
						i.key = this.items_layout[0].items.length
						this.items_layout[0].items.push(i)
					})
				}
			},
			getExtraFields(){
				this.extra_items = []

				this.$wsc.getList(this.path + '/' + this.$route.params[this.$route.name] + '/extra', {}, items => {
					this.extra_items = items

					this.ready_layout--

				}, fail => {

				})
			},
			getFieldGroups(){
				this.$wsc.getList('resource/fieldgroup/' + this.$route.name, {}, items => {
					this.processFieldGroups(items)
				}, fail => {

				})
			},
			getFieldsFromGroup(group){
				let res = {}

				for(let obj of group.fields){
					if(typeof this.fields[obj.name] !== 'undefined'){
						this.$set(res, obj.name, this.fields[obj.name])
					}
				}

				return res
			},
			getFields(){
				this.fields = {}

				this.$wsc.getOptions(this.$route.params, {}, this.path + (isNaN(parseInt(this.$route.params[this.$route.name])) ? '' : '/' + this.$route.params[this.$route.name]), success => {
					if(this.mode === 'creation' || this.mode === 'multiple_creation'){
						let res = {}

						Object.keys(success.fields).forEach(e => {
							if(!success.fields[e].read_only){
								res[e] = success.fields[e]
							}
						})

						this.fields = res
						this.ready = true
					}
					else {
						if(success['fields'].created){
							this.$set(this.default_fields, "created", success.fields['created'])
							this.$delete(success.fields, 'created')
						}
						if(success['fields'].modified){
							this.$set(this.default_fields, "modified", success.fields['modified'])
							this.$delete(success.fields, 'modified')
						}
						if(success['fields'].unique_id){
							this.$set(this.default_fields, "unique_id", success.fields['unique_id'])
							this.$delete(success.fields, 'unique_id')
						}
						if(success['fields'].id){
							this.$set(this.default_fields, "id", success.fields['id'])
							this.$delete(success.fields, 'id')
						}
						if(success['fields'].display_name){
							this.$set(this.default_fields, "display_name", success.fields['display_name'])
							this.$delete(success.fields, 'display_name')
						}
						if(success['fields'].logo){
							this.$set(this.logo_field, "logo", success.fields['logo'])
							this.$delete(success.fields, 'logo')
						}
						if(success['fields'].id_profil){
							this.$set(this.logo_field, "id_profil", success.fields['id_profil'])
							this.$delete(success.fields, 'id_profil')
						}
						if(success.fields['status']){
							this.$set(this.fieldStatus, 'name', 'status')
							this.$set(this.fieldStatus, 'fields', {status: success.fields['status'], status_progression: success.fields['status_progression']})
							this.$delete(success.fields, 'status_progression')
							this.$delete(success.fields, 'status')
						}

						if(success.fields['tag']){
							this.tag_field = success.fields['tag']
							this.$delete(success.fields, 'tag')
						}

						this.fields = success.fields

						this.getItem()

						if(success.actions.length > 0) {
							this.actions = success.actions
						}
					}

					this.ordering = success.ordering

					this.getFieldGroups()
				}, fail => {
				})
			},
			getItem(){
				this.$wsc.getItem(this.path, isNaN(parseInt(this.$route.params[this.$route.name])) ? '' : this.$route.params[this.$route.name], success => {
					this.item = success
					this.old_val = JSON.parse(JSON.stringify(success))
					this.ready = true

					if(success.__meta__.methods.length > 0) {
						this.methods = success.__meta__.methods
						this.mode = (success.__meta__.methods.includes('update') ? 'edition' : 'readonly')
					}
				}, fail => {

				})
			},
			getWidgets(){
				this.$wsc.getList(/*this.path + '/' + this.$route.params[this.$route.name] + */ 'widget', {}, success => {
					this.widgets = success
					this.ready_layout--
				}, fail => {

				})
			},
			gridContainer(e){
				if(this.$vuetify.breakpoint.width < 600){
					this.cols = 1
					this.unit = (e[0].target.clientWidth - 2 * 8) / this.cols
					return
				}

				this.cols = 32

				this.unit = (e[0].target.clientWidth - (this.edit_items ? 7 : 5) * 8 - (this.cols - 1) * 8) / this.cols
			},
			initItemPage(){
				this.template_layout = [
					{
						title: 'Widgets',
						open: true,
						items: []
					},
					{
						title: 'Default',
						open: true,
						items: []
					}
				]

				this.ready_layout = 3
				this.ready = false
				this.item = {}

				this.getFields()

				if(this.routeComponents.includes('widgets') && this.mode !== 'creation' && this.mode !== 'multiple_creation')
					this.getWidgets()
				else {
					this.ready_layout--
				}

				if(this.routeComponents.includes('extra') && this.mode !== 'creation' && this.mode !== 'multiple_creation')
					this.getExtraFields()
				else {
					this.ready_layout--
				}
			},
			initLayout() {
				this.template_layout = [
					{
						title: 'Widgets',
						open: true,
						items: []
					},
					{
						title: 'Default',
						open: true,
						items: []
					}
				]

				this.makeLayout()

				if(Object.keys(this.$store.getters['profile/items_layout']).length === 0){
					this.items_layout = this.template_layout
				}
				else if(typeof this.$store.getters['profile/items_layout'][this.$route.name] === 'undefined'){
					this.items_layout = this.template_layout
				}
				else {
					let res = []

					this.template_layout.forEach(tr => {
						tr.items.forEach(tw => {
							let obj = this.reOrder(tw)

							tw.data.display_rules = obj.display_rules

							if(Object.keys(obj).length > 0) {
								if (typeof res[obj.row] === 'undefined'){
									res[obj.row] = {
										title: obj.title,
										items: [],
										open: true
									}
								}

								res[obj.row].items.splice(obj.col, 0, tw)
							}
							else if(obj) {
								let index = res.findIndex(e => e.title === 'Default')
								if(index !== -1){
									res[index].items.push(tw)
								}
								else {
									res.push({
										title: 'Default',
										open: true,
										items: []
									})

									res[res.length - 1].items.push(tw)
								}
							}
							else {
							}
						})
					})

					this.items_layout = res
				}
			},
			reOrder(widget) {
				for (let i = 0; i < this.$store.getters['profile/items_layout'][this.$route.name].length; i++) {
					let r = this.$store.getters['profile/items_layout'][this.$route.name][i]

					for (let j = 0; j < r.items.length; j++) {
						let w = r.items[j]

						if(widget.type === 'widget'){
							if(w.data.id === widget.data.id) {
								return {title: r.title, row: i, col: j, display_rules: w.data.display_rules}
							}
						}
						else if(widget.type === 'status'){
							if(w.name === 'status'){
								return {title: r.title, row: i, col: j, display_rules: w.data.display_rules}
							}
						}
						else if(widget.type === 'extra'){
							if(w.name === 'extra'){
								return {title: r.title, row: i, col: j, display_rules: w.data.display_rules}
							}
						}
						else if(widget.type === 'fieldgroup'){
							if(w.name === widget.name){
								return {title: r.title, row: i, col: j, display_rules: w.data.display_rules}
							}
						}
						else if(widget.type === 'set'){
							if(w.data.endpoint === widget.data.endpoint){
								return {title: r.title, row: i, col: j, display_rules: w.data.display_rules}
							}
						}
						else if(widget.type === 'comment'){
							if(w.name === 'comment') {
								return {title: r.title, row: i, col: j, display_rules: w.data.display_rules}
							}
						}
						else if(widget.type === 'activity'){
							if(w.name === 'activity') {
								return {title: r.title, row: i, col: j, display_rules: w.data.display_rules}
							}
						}
					}
				}

				return true
			},
			makeLayout(){
				let widgetsFound = this.routeComponents.includes('widgets')

				if (widgetsFound) {
					this.widgets.forEach(w => {
						this.template_layout[0].items.push({
							name: w.display_name,
							type: 'widget',
							data: w,
							key: this.template_layout[0].items.length
						})
					})
				}
				else {
					this.template_layout.splice(0, 1)
				}

				this.field_groups.forEach(f => {
					if (f.fields.length > 0){
						this.template_layout[widgetsFound ? 1 : 0].items.push({
							name: f.name,
							type: 'fieldgroup',
							data: {
								name: f.name,
								fieldsArray: f.fields,
								fields: this.getFieldsFromGroup(f),
								mode: this.mode
							},
							key: this.template_layout[widgetsFound ? 1 : 0].items.length
						})
					}
				})

				if (Object.keys(this.fieldStatus).length > 0){
					this.template_layout[widgetsFound ? 1 : 0].items.push({
						name: 'status',
						type: 'status',
						key: this.template_layout[widgetsFound ? 1 : 0].items.length,
						data: this.fieldStatus
					})
				}

				if (this.routeComponents.includes('extra')){
					this.template_layout[widgetsFound ? 1 : 0].items.push({
						name: 'extra',
						type: 'extra',
						key: this.template_layout[widgetsFound ? 1 : 0].items.length,
						data: {items: this.extra_items}
					})
				}

				this.routeChildren.forEach(r => {
					if(r.position === 'item_info'){
						this.template_layout[widgetsFound ? 1 : 0].items.push({
							name: r.endpoint,
							type: 'set',
							data: r,
							key: this.template_layout[widgetsFound ? 1 : 0].items.length
						})
					}
				})

				if(this.routeComponents.includes('comment')){
					this.template_layout[widgetsFound ? 1 : 0].items.push({
						name: 'comment',
						type: 'comment',
						key: this.template_layout[widgetsFound ? 1 : 0].items.length,
						data: {}
					})
				}

				if(this.routeComponents.includes('activity')){
					this.template_layout[widgetsFound ? 1 : 0].items.push({
						name: 'activity',
						type: 'activity',
						key: this.template_layout[widgetsFound ? 1 : 0].items.length,
						data: {}
					})
				}

			},
			processFieldGroups(fields){
				this.field_groups = [
					{
						name: 'default',
						fields: []
					}
				]

				for(let key in fields){
					let group = this.field_groups.find(g => g.name === fields[key])

					if(typeof group !== 'undefined' && this.fields[key]){
						group.fields.push({name: key, label: this.fields[key].label})
					}
					else if(this.fields[key]) {
						this.field_groups.push({
							name: fields[key],
							fields: [{name: key , label: this.fields[key].label}]
						})
					}

				}

				for(let key in this.field_groups){
					this.field_groups[key].fields.sort((a, b) => {
						if(a.label < b.label){
							return -1
						}
						else {
							return 1
						}
					})
				}

				this.ready_layout--

				if(this.field_groups.length === 1)
					this.field_groups.push({name: '', fields: []})

				this.field_groups_copy = JSON.parse(JSON.stringify(this.field_groups))
				this.pevents.notify('fieldGroups', this.field_groups)
			},
			closeBottomSheet(){
				this.events.notify('close-children')
				this.bottom_sheet = false
			},
			saveChanges() {
				this.loading = true

				let data = {}

				for(let key of this.fields_changed){
					if(this.fields[key].type === 'datetime' && this.item[key] === ''){
						data[key] = null
					}
					else {
						data[key] = this.item[key]
					}
				}

				this.$wsc.patchItem(this.path, this.$route.params[this.$route.name], data, success => {
					this.loading = false
					this.item = success
					this.closeBottomSheet()

					this.getFields()

					this.events.notify('parent-saved', success)
					this.$store.dispatch('snackbar/success', this.$t('snackbar.EntityField.saveChanges.success'))
				}, fail => {
					this.loading = false
				})
			},
			saveItemsLayout(){
				this.loading = true
				this.items_layout.forEach((layout, i) => {
					if (layout.title === '' && layout.items.length === 0)
						this.items_layout.splice(i,1)
				})
				this.$store.commit('profile/CHANGE_ITEMS_LAYOUT_VAL', {entity: this.$route.name, val: this.items_layout, callback: (val) => {
						if(val === 'success'){
							this.$store.dispatch('snackbar/success', this.$t('snackbar.Item.saveLayout.success'))
						}
						else {
							//this.$store.dispatch('snackbar/error', this.$t('snackbar.Item.saveLayout.fail'))
						}

						this.loading = false
						this.edit_items = false
					}
				})
			},
			saveFieldGroups(){
				let res = {}

				this.field_groups.forEach(e => {
					e.fields.forEach(f => {
						this.$set(res, f.name, e.name)
					})
				})

				this.loading = true

				this.$wsc.post('resource/fieldgroup/' + this.$route.name, res, success => {
					this.drawer = !this.drawer
					this.drawer_dashboard = !this.drawer
					this.$store.dispatch('snackbar/success', this.$t('snackbar.Item.saveFieldGroups.success'))
					this.processFieldGroups(success)
					this.initLayout()
					this.loading = false
				}, fail => {
					this.drawer = !this.drawer
					this.drawer_dashboard = !this.drawer
					this.loading = false
				})
			},
			scrollForm(){
				setTimeout(() => this.$vuetify.goTo('#form_' + (this.forms.length - 1), {
					duration: 800,
					offset: 12,
					easing: 'easeInOutCubic',
				}), 300)
			},
			setUpHooks(){
				this.events = new this.$NVEvent("Item")

				this.events.wait('refresh', () => {
					this.computePath()
				})

				this.events.wait('fields-changed', (fields) => {
					this.fields_changed = fields
				})

				this.pevents.wait('update-app', () => {
					this.computePath()
				})

				this.events.wait('update', () => {
					this.getFields()
				})
			}
		},
		created() {
			this.setUpHooks()
			this.computePath()
		},
		mounted() {
			new ResizeObserver(this.gridContainer).observe(this.$refs.container)
		},
		beforeRouteLeave(to, from, next){
			if (this.drawer) {
				this.dialog_save = true
				this.to = to
			}
			else {
				this.$store.commit('dashboard/CHANGE_DRAWER_ITEM_VAL', false)
				next()
			}
		}
	}
</script>

<style scoped>
	.gridcontainer {
		display: grid;
		grid-gap: 8px;
		grid-template-columns: repeat(2, 1fr);
		grid-auto-rows: auto;
	}
	.gridcontainer > * {
	}

	.handle2 {
		cursor: grab;
	}
</style>
